import { createAction, props } from '@ngrx/store';
import {
  LOAD_SCHEDULED_REPORTS,
  LOAD_SCHEDULED_REPORTS_ERROR,
  STORE_SCHEDULED_REPORTS,
} from './action-types';

export const loadScheduledReports = createAction(
  LOAD_SCHEDULED_REPORTS,
  props<{ forceFetch: boolean }>()
);
export const loadScheduledReportsSuccess = createAction(
  STORE_SCHEDULED_REPORTS,
  props<{ payload: any[] }>()
);
export const loadScheduledReportsFailure = createAction(
  LOAD_SCHEDULED_REPORTS_ERROR,
  props<{ error: any }>()
);
